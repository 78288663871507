
@font-face {
    font-family: 'SF New Republic';
    src: url('/assets/fonts/SFNewRepublic.woff2') format('woff2'),
        url('/assets/fonts/SFNewRepublic.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}


$fonts : (
    one: "SF New Republic",
    two: "Montserrat",
    three: "Roboto",
    four: "Poppins"
);

