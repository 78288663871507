
$colors: (
    one: #253f6e,
    two: #cca742,
    three: #a2a2a2,
    four: #f6f6f6,
    five: #0b2241,
    six: #1d4980,
    seven: #464646,
    eight: #f7f7f7,
    nine: #208390,
    ten: #f9f9f9,
    eleven: #146f7c,
    twelve: #32a0ae
);

.btn-one{
    background: map-get($colors,one);
    border-radius: 5px;
    padding: 15px 20px;
    color: #fff !important;
    border: 0;
    cursor: pointer;
    -webkit-box-shadow: 5px 5px 7px -5px #303030;
    box-shadow: 5px 5px 7px -5px #303030;
}
.btn-two{
    background: map-get($colors,two);
    color: #FFF;
    border-radius: 5px;
    padding: 10px 20px;
    border: 0;
    cursor: pointer;
    box-shadow: 5px 5px 7px -5px #303030;
    -webkit-box-shadow: 5px 5px 7px -5px #303030;
}
.btn-icon{
    display: flex;
    align-items: center;
}
.btn-icon i,.btn-icon img{
    font-size: 18px;
    margin: 0 10px 0 0;
}
.btn-orange,.btn-green{
    color: #FFF;
    border-radius: 5px;
    padding: 12px 20px;
    border: 0;
    cursor: pointer;
    -webkit-transition: all 500ms ease;
    -moz-transition: all 500ms ease;
    -ms-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    transition: all 500ms ease;
    font-weight: bold;  
}
.btn-green{
    background: #07a670;
    border: 2px solid #b9ffe7;
}
.btn-orange{
    background: #e78609; 
    border: 2px solid #ffc376;
}
.btn-orange:hover{
    background: #FFF;
    color: #e78609;
    border: 2px solid #e78609;
}