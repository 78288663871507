@font-face {
  font-family: "SF New Republic";
  src: url("/assets/fonts/SFNewRepublic.woff2") format("woff2"), url("/assets/fonts/SFNewRepublic.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
.btn-one {
  background: #253f6e;
  border-radius: 5px;
  padding: 15px 20px;
  color: #fff !important;
  border: 0;
  cursor: pointer;
  -webkit-box-shadow: 5px 5px 7px -5px #303030;
  box-shadow: 5px 5px 7px -5px #303030;
}

.btn-two {
  background: #cca742;
  color: #FFF;
  border-radius: 5px;
  padding: 10px 20px;
  border: 0;
  cursor: pointer;
  box-shadow: 5px 5px 7px -5px #303030;
  -webkit-box-shadow: 5px 5px 7px -5px #303030;
}

.btn-icon {
  display: flex;
  align-items: center;
}

.btn-icon i, .btn-icon img {
  font-size: 18px;
  margin: 0 10px 0 0;
}

.btn-orange, .btn-green {
  color: #FFF;
  border-radius: 5px;
  padding: 12px 20px;
  border: 0;
  cursor: pointer;
  -webkit-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
  font-weight: bold;
}

.btn-green {
  background: #07a670;
  border: 2px solid #b9ffe7;
}

.btn-orange {
  background: #e78609;
  border: 2px solid #ffc376;
}

.btn-orange:hover {
  background: #FFF;
  color: #e78609;
  border: 2px solid #e78609;
}

@font-face {
  font-family: "SF New Republic";
  src: url("/assets/fonts/SFNewRepublic.woff2") format("woff2"), url("/assets/fonts/SFNewRepublic.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
.btn-one {
  background: #253f6e;
  border-radius: 5px;
  padding: 15px 20px;
  color: #fff !important;
  border: 0;
  cursor: pointer;
  -webkit-box-shadow: 5px 5px 7px -5px #303030;
  box-shadow: 5px 5px 7px -5px #303030;
}

.btn-two {
  background: #cca742;
  color: #FFF;
  border-radius: 5px;
  padding: 10px 20px;
  border: 0;
  cursor: pointer;
  box-shadow: 5px 5px 7px -5px #303030;
  -webkit-box-shadow: 5px 5px 7px -5px #303030;
}

.btn-icon {
  display: flex;
  align-items: center;
}

.btn-icon i, .btn-icon img {
  font-size: 18px;
  margin: 0 10px 0 0;
}

.btn-orange, .btn-green {
  color: #FFF;
  border-radius: 5px;
  padding: 12px 20px;
  border: 0;
  cursor: pointer;
  -webkit-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
  font-weight: bold;
}

.btn-green {
  background: #07a670;
  border: 2px solid #b9ffe7;
}

.btn-orange {
  background: #e78609;
  border: 2px solid #ffc376;
}

.btn-orange:hover {
  background: #FFF;
  color: #e78609;
  border: 2px solid #e78609;
}

/** DM CHECKBOX **/
.dm-check-box {
  display: table;
  margin: 0 0 5px 0;
}

.dm-check-box-input {
  border-radius: 20px;
  width: 20px;
  height: 20px;
  display: table;
  border: 2px solid #eee;
  background: #FFF;
  margin: 0 10px 0 0;
  cursor: pointer;
}

.dm-check-box-input.active {
  background: #cca742;
}

.dm-check-box-input,
.dm-check-box-value {
  display: table;
  float: left;
}

.dm-check-box-value {
  text-transform: uppercase;
  position: relative;
  top: -2px;
}

.dm-check-box-info {
  font-size: 10px;
  color: #253f6e;
  top: -2px;
}

/** MODAL DIALOG **/
.modal-dialog .message-text {
  padding: 10px;
}

.produto-form-search-two .mat-select-value-text {
  font-size: 12px;
  position: relative;
  top: 4px;
}

/** FORM CUSTOM **/
.form-custom label {
  float: left;
  position: relative;
  width: 100%;
  text-align: left;
  font-weight: 500;
  font-size: 13px;
  font-weight: 500;
}

.form-custom label.bold {
  font-weight: 700;
}

.form-custom label i {
  padding-right: 12px;
  font-size: 14px;
  position: absolute;
  top: 16px;
  left: 16px;
}

.form-custom input[type=text],
.form-custom input[type=password],
.form-custom textarea,
.form-custom mat-select {
  float: left;
  border: none;
  background: transparent;
  width: 100%;
  padding: 10px 0;
  border-radius: 0;
  font-size: 13px;
  -webkit-appearance: none;
  border-bottom: 1px solid #9D9D9C;
  outline: none;
}

.form-custom input::-ms-input-placeholder {
  font-size: 13px;
}

.form-custom input::-webkit-input-placeholder {
  font-size: 13px;
}

.form-custom input::placeholder {
  font-size: 13px;
}

.form-custom textarea::-ms-input-placeholder {
  font-size: 13px;
}

.form-custom textarea::-webkit-input-placeholder {
  font-size: 13px;
}

.form-custom textarea::placeholder {
  font-size: 13px;
}

.form-custom textarea {
  resize: none;
  -webkit-appearance: none;
}

.form-custom mat-select {
  padding: 8px !important;
}

.form-custom mat-select .mat-select-value-text {
  position: relative;
  top: 5px;
  font-size: 12px;
}

.form-group {
  display: table;
  width: 100%;
  margin: 0 0 15px 0;
}

/** select 2 **/
.select2-container .select2-selection--single {
  height: 45px;
  outline: none !important;
  padding: 7px;
  background: #f6f6f6 !important;
  border-color: #eee;
  border-radius: 5px;
}

.select2-container--default .select2-selection--single .select2-selection__arrow b {
  top: 80%;
  margin-left: -10px;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
  color: #000;
  font-size: 13px;
}

.select2, .select2-hidden-accessible {
  /**width: 100% !important;**/
  max-width: 100% !important;
  table-layout: fixed;
  display: table;
}

.select2 {
  width: 100% !important;
}

.select2-results__option[aria-selected] {
  padding: 10px !important;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: #253f6e !important;
}

.input-box {
  margin: 0 0 15px 0;
  display: table;
  width: 100%;
}

.mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  background: #fff;
  color: rgba(0, 0, 0, 0.87);
}

.mat-dialog-title {
  font: 500 20px/32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-expansion-panel-header {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 15px;
  font-weight: 400;
}

.mat-expansion-panel-content {
  font: 400 14px/20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 10000000;
}

.cdk-overlay-container:empty {
  display: none;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}

.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}

@media screen and (-ms-high-contrast: active) {
  .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
    opacity: 0.6;
  }
}
.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}

.cdk-overlay-transparent-backdrop, .cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
}

body, html {
  overflow-x: hidden;
}

body {
  font-family: "Poppins" !important;
  font-weight: 300;
}

h1, h2, h3, h4 {
  font-family: "SF New Republic";
}

ul {
  margin: 0;
}

footer h3 {
  font-family: "Roboto" !important;
  font-weight: 500 !important;
}

.slide-navigate-item {
  display: flex;
  align-items: center;
  position: absolute;
  top: 32%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.slide-navigate-item.prev {
  left: -20px;
}

.slide-navigate-item.next {
  right: -20px;
}

.slide-navigate-item i {
  color: #253f6e;
}

.slide-navigate-item.template-2 {
  background: transparent;
  box-shadow: none;
}

.slide-navigate-item.template-2 i {
  color: #FFF;
  font-size: 70px;
}

.box-sombra {
  background: #FFF;
  box-shadow: 5px 5px 20px 0 #d4d4d4;
  padding: 20px;
}

header.fixed .logo img {
  width: 100px !important;
}

.input-float input, .input-float textarea {
  border: 1px solid #a2a2a2 !important;
  padding: 15px 20px !important;
  border-radius: 10px !important;
}

.input-float label {
  background: #FFF;
  position: absolute;
  display: table;
  width: auto;
  padding: 0 10px;
  color: #a2a2a2;
  margin-left: 10px;
  margin-top: -5px;
}

.input-float {
  margin-bottom: 20px;
  display: table;
  width: 100%;
}

.content-chamada {
  text-align: center;
}

.content-chamada strong {
  font-weight: bold;
  color: #253f6e;
}

.content-chamada article {
  line-height: 30px;
  font-size: 22px;
}

/** page title **/
.page-title h1 {
  color: #253f6e;
  font-size: 42px;
  font-weight: 600;
}

.page-title h2 {
  font-size: 22px;
  font-family: "Poppins";
}

article strong {
  font-weight: bold;
  color: #253f6e;
}

.page-about article p {
  margin: 0 0 15px 0;
}

.page-about .section-vision article strong {
  color: #fff !important;
}

.page-politicas .section-description p {
  line-height: 30px;
  margin: 0 0 10px 0;
}

.page-politicas .section-description ul {
  list-style: disc;
  padding: 0 0 0 20px;
}

.page-politicas .section-description ul li {
  margin: 0 0 5px 0;
}

/** depoimento item **/
.depoimento-item .content-depoimento article {
  text-align: justify;
  line-height: 20px;
}

.depoimento-item .content-depoimento article p {
  margin: 0 0 10px 0;
  font-size: 13px;
  font-weight: 400;
}

.swiper-button-next,
.swiper-button-prev {
  display: flex;
  position: absolute;
  top: 35%;
  cursor: pointer;
}

.swiper-button-prev {
  left: -30px;
}

.swiper-button-next {
  right: -30px;
}

.plano-item strong {
  font-weight: 600;
}

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1280px !important;
  }
}
@media (max-width: 767px) {
  .slick-padding-mobile .slick-list {
    padding: 10px 20px 10px 10px !important;
  }

  footer .module-title {
    margin: 30px 0 0 0 !important;
  }

  .module .title h2,
.module-title h2 {
    font-size: 30px !important;
  }

  .module .title h3,
.module-title h3 {
    font-size: 18px !important;
  }
}